import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/plugins/element-ui";
import "@/plugins/flicking-slider";
import "./assets/tailwind.css";

import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faBuilding,
  faPhone,
  faEnvelope,
  faSearchPlus,
  faBoxOpen,
  faLock,
  faFingerprint,
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(
  faBuilding,
  faPhone,
  faEnvelope,
  faSearchPlus,
  faBoxOpen,
  faLock,
  faFingerprint
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
