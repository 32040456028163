<template>
  <nav class="w-full flex items-center justify-center bg-white border-gray-200">
    <div
      class="w-full max-w-7xl flex flex-wrap items-center justify-between mx-auto menuSetting px-6 py-3"
    >
      <router-link to="/">
        <img
          loading="lazy"
          src="../assets/trisega_logo.png"
          width="120"
          alt="Logo-curaduria"
        />
      </router-link>
      <div class="flex md:order-2"></div>
      <el-menu
        :default-active="activeIndex"
        class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
        mode="horizontal"
      >
        <!-- @select="handleSelect" -->
        <router-link to="/">
          <el-menu-item index="1"> Inicio </el-menu-item>
        </router-link>
        <router-link to="/quienes-somos">
          <el-menu-item index="2"> Nosotros </el-menu-item>
        </router-link>
        <el-submenu index="5">
          <template slot="title"> Servicios </template>
          <router-link to="/servicios?page=trazabilidad">
            <!-- <div> -->
            <el-menu-item index="5-1">Trazabilidad</el-menu-item>
            <!-- </div> -->
          </router-link>
          <router-link to="/servicios?page=inventarios">
            <!-- <div> -->
            <el-menu-item index="5-2">Inventarios</el-menu-item>
            <!-- </div> -->
          </router-link>
          <router-link to="/servicios?page=seguridad">
            <el-menu-item index="5-3">Seguridad</el-menu-item>
          </router-link>
          <router-link to="/servicios?page=autenticidad">
            <el-menu-item index="5-4">Autenticidad</el-menu-item>
          </router-link>
        </el-submenu>
        <router-link to="/contacto">
          <el-menu-item index="6"> Contáctenos </el-menu-item>
        </router-link>
      </el-menu>
    </div>
    <menuLateral class="flex md:hidden" />
  </nav>
</template>
<script>
export default {
  name: "KCHeader",
  components: {
    menuLateral: () => import("@/components/_menuLateral/_menuLateral.vue"),
  },
  created() {
    this.setMenu();
  },
  data() {
    return {
      activeIndex: "1",
    };
  },
  computed: {
    showMenu: {
      get() {
        return this.$store.state.showMenu;
      },
      set(newValue) {
        this.$store.commit("ACTIONS_MENU", newValue);
      },
    },
  },
  methods: {
    actionsMenu() {
      this.showMenu = true;
    },
    setMenu() {
      if (this.$route.path) {
        switch (this.$route.path) {
          case "/":
            this.activeIndex = "1";
            break;
          case "/quienes-somos":
            this.activeIndex = "2";
            break;
          case "/servicios":
            this.activeIndex = "3";
            break;
          case "/requisitos":
            this.activeIndex = "4";
            break;
          case "/consultas":
            this.activeIndex = "5";
            break;
          case "/contacto":
            this.activeIndex = "6";
            break;
        }
      }
    },
  },
  watch: {
    $route() {
      this.setMenu();
    },
  },
};
</script>

<style scoped>
.menuSetting >>> .el-menu-item:focus,
.el-menu-item:hover {
  background-color: transparent;
}
.menuSetting >>> .el-menu.el-menu--horizontal {
  border-bottom: solid 1px transparent;
}
.menuSetting >>> .el-dropdown-menu__item,
.menuSetting >>> .el-menu-item,
.menuSetting >>> .el-submenu__title {
  font-size: 16px;
  padding: 0 12px;
}
.menuSetting >>> .el-menu-item,
.menuSetting >>> .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  color: #999999 !important;
}
.menuSetting >>> .el-menu-item.is-active {
  border-bottom: 2px solid #f97a25 !important;
  color: #999999 !important;
}
.menuSetting >>> .el-menu-item:focus {
  color: #999999 !important;
}
.menuSetting >>> .el-menu-item:hover {
  color: #f97a25 !important;
}
.menuSetting
  >>> .el-menu--horizontal
  > .el-submenu.is-active
  .el-submenu__title {
  border-bottom: 2px solid #f97a25 !important;
  color: #999999;
}
</style>
