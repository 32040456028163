import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "indexLanding",

    component: function () {
      return import("../views/index.vue");
    },
  },
  {
    path: "/quienes-somos",
    name: "quienes-somos",

    component: function () {
      return import("../views/KC_nosotros.vue");
    },
  },
  {
    path: "/contacto",
    name: "contacto",
    component: function () {
      return import("../views/KC_contact.vue");
    },
  },
  {
    path: "/servicios",
    name: "servicios",
    component: function () {
      return import("../views/KC_consult.vue");
    },
  },
  {
    path: "*",
    component: function () {
      return import("../views/index.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
