import Vue from "vue";
import {
  Collapse,
  CollapseItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  Dialog,
  Drawer,
  Message,
  Skeleton,
  SkeletonItem,
  Tooltip,
} from "element-ui";
import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";
import "../../public/element-ui.css";

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Drawer);
Vue.use(Tooltip);
Vue.use(Skeleton);
Vue.use(SkeletonItem);

Vue.prototype.$message = Message;
locale.use(lang);
